<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Våre Badromsløsninger</h2>
			<p>
				Uansett om du er på utkikk etter et splitter nytt toppmoderne bad eller om du kun ønsker en smartere dusjøsning, vi i Ellingsen Byggservice leverer skreddersydde badromsløsninger fra Aubo til ditt hjem. 
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 1000px;
	max-width: 70%;
	padding-top: 2%;
}

</style>
